import styled from 'styled-components';
import GoogleLogin from '../LoginForm/GoogleLogin';
import SocialSignInButton from 'components/components/auth/common/SocialSignInButton';
import { authPlatform, mainFlow } from 'constants/authFlow';
import FacebookLoginButton from 'components/components/auth/LoginForm/FacebookLoginButton';
import { useAuth } from 'hooks/useAauth';
import useUser from 'hooks/useUser';
import LoginKeys from 'translations/translationKeys/LoginPageKeys';

const { METAMASK, GOOGLE, FACEBOOK } = authPlatform;
const { SIGN_UP, REACTIVATE, EMAILNOTPRESENT } = mainFlow;
const SocialSignupSection = ({ flowManager, setError, setIsLoading }) => {
  const { setSharedValue, navigateFlow, closeModal } = flowManager;
  const { utils: authUtils } = useAuth();
  const { manager: googleAuthManager, errorHandlers } = useAuth(GOOGLE);
  const { manager: facebookAuthManager } = useAuth(FACEBOOK);
  const { refetch } = useUser();

  async function handleFacebookSuccess(fbResponse) {
    setIsLoading(true);
    if (!fbResponse.email) {
      navigateFlow({
        flow: EMAILNOTPRESENT,
        state: SIGN_UP,
        step: 0,
      });
      setIsLoading(false);
      return;
    }
    setSharedValue({
      userData: {
        email: fbResponse.email,
        fbAccessToken: fbResponse.accessToken,
        pic: fbResponse.picture.data.url,
        name: fbResponse.name,
      },
    });
    try {
      const isEmailRegistable = await authUtils.checkEmailIsRegistable(
        fbResponse.email
      );
      if (isEmailRegistable) {
        navigateFlow({
          flow: SIGN_UP,
          method: FACEBOOK,
          step: 1,
        });
      }
    } catch (error) {
      // setError(error.message);
      if (error.message === 'LoginKeys.Error.already_in_use') {
        await facebookAuthManager.login(
          {
            email: fbResponse.email,
            fbAccessToken: fbResponse.accessToken,
            loginType: FACEBOOK,
          },
          {
            onSuccess: () => {
              refetch()
                .then(() => {
                  closeModal();
                })
                .catch((e) => {
                  console.log(e);
                });
            },
            onError: (error) => {
              if (errorHandlers.deactivated(error)) {
                navigateFlow({
                  flow: REACTIVATE,
                  step: 0,
                });
              } else {
                setIsLoading(false);
                if (
                  error.response?.status === 500 ||
                  error.response?.status === 502
                ) {
                  setError(LoginKeys.Error.network);
                } else {
                  setError(error.response.data.message);
                }
              }
            },
          }
        );
      }
    }
  }

  async function handleGoogleSuccess(userData) {
    setIsLoading(true);
    setSharedValue({
      userData,
    });
    try {
      const isEmailRegistable = await authUtils.checkEmailIsRegistable(
        userData.email
      );
      if (isEmailRegistable) {
        navigateFlow({
          flow: SIGN_UP,
          method: GOOGLE,
          step: 1,
        });
        setIsLoading(false);
      }
    } catch (error) {
      if (error.message === 'LoginKeys.Error.already_in_use') {
        setIsLoading(true);

        await googleAuthManager.login(
          {
            email: userData.email,
            googleToken: userData.googleToken,
            loginType: GOOGLE,
          },
          {
            onSuccess: () => {
              refetch()
                .then(() => {
                  closeModal();
                })
                .catch((e) => {
                  console.log(e);
                });
            },
            onError: (error) => {
              if (errorHandlers.deactivated(error)) {
                navigateFlow({
                  flow: REACTIVATE,
                  step: 0,
                });
              } else {
                setIsLoading(false);
                if (
                  error.response?.status === 500 ||
                  error.response?.status === 502
                ) {
                  setError(LoginKeys.Error.network);
                } else {
                  setError(error.response.data.message);
                }
              }
            },
          }
        );
        return;
      }
      setIsLoading(false);
      setError(error.message);
    }
  }

  return (
    <>
      <GButtonWrapper>
        <GoogleLogin onSuccess={handleGoogleSuccess} context="signup_with" />
      </GButtonWrapper>

      {/* <FacebookLoginButton
        style={{ marginTop: '-16.5px' }}
        context="register"
        responseCallback={handleFacebookSuccess}
      /> */}
      {/* <SocialSignInButton
        type="wallet"
        context="register"
        onClick={() =>
          navigateFlow({
            flow: SIGN_UP,
            method: METAMASK,
            step: 1,
          })
        }
      /> */}
    </>
  );
};

export default SocialSignupSection;

const GButtonWrapper = styled.div`
  height: 40px;
`;

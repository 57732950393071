import styled from 'styled-components';
import GoogleLogin from '../LoginForm/GoogleLogin';
import SocialSignInButton from 'components/components/auth/common/SocialSignInButton';
import FacebookLoginButton from 'components/components/auth/LoginForm/FacebookLoginButton';
import { useAuthFlowManager } from 'contexts/AuthFlowManagerContext';
import { authPlatform, mainFlow } from 'constants/authFlow';
import { useAuth } from 'hooks/useAauth';
import useUser from 'hooks/useUser';
import LoginKeys from 'translations/translationKeys/LoginPageKeys';
import { useWebViewIssueBanner } from 'contexts/WebViewIssueBannerContext';

const { METAMASK, GOOGLE, FACEBOOK } = authPlatform;
const { SIGN_UP, SIGN_IN, REACTIVATE, EMAILNOTPRESENT } = mainFlow;

const SocialLoginSection = ({ setError, setIsLoading, setLoginStarted }) => {
  const { navigateFlow, closeModal, setSharedValue } = useAuthFlowManager();
  const { manager: googleAuthManager, errorHandlers } = useAuth(GOOGLE);
  const { manager: facebookAuthManager } = useAuth(FACEBOOK);
  const { refetch } = useUser();
  const { openWebViewIssueBanner, isNative } = useWebViewIssueBanner();

  async function handleFacebookSuccess(userData) {
    if (!userData.email) {
      navigateFlow({
        flow: EMAILNOTPRESENT,
        state: SIGN_IN,
        step: 0,
      });
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    try {
      await facebookAuthManager.login(
        {
          email: userData.email,
          fbAccessToken: userData.accessToken,
          loginType: FACEBOOK,
        },
        {
          onSuccess: () => {
            refetch().then(() => {
              closeModal();
            });
          },
          onError: (error) => {
            if (errorHandlers.userNotFound(error)) {
              setSharedValue({
                userData: {
                  ...userData,
                  fbAccessToken: userData.accessToken,
                },
              });
              navigateFlow({
                flow: SIGN_UP,
                method: FACEBOOK,
                step: 1,
              });
            } else if (errorHandlers.deactivated(error)) {
              navigateFlow({
                flow: REACTIVATE,
                step: 0,
              });
            } else {
              if (
                error.response?.status === 500 ||
                error.response?.status === 502
              ) {
                setError(LoginKeys.Error.network);
              } else if (error.key === 'EMAIL_ALREADY_EXISTS') {
                setError(
                  LoginKeys.Error.already_in_use_or_connect_another_method
                );
              } else {
                setError(error.response.data.message);
              }
            }
          },
          onFinal: () => {
            setIsLoading(false);
          },
        }
      );
    } catch (error) {
      throw error;
    }
  }

  async function handleGoogleSuccess(userData) {
    if (isNative) return openWebViewIssueBanner();

    setIsLoading(true);
    setLoginStarted(true);
    try {
      await googleAuthManager.login(
        {
          email: userData.email,
          googleToken: userData.googleToken,
          loginType: GOOGLE,
        },
        {
          onSuccess: () => {
            refetch()
              .then(() => {
                closeModal();
              })
              .catch((e) => {
                console.log(e);
              })
              .finally(() => {
                setIsLoading(false);
                setLoginStarted(false);
              });
          },
          onError: (error) => {
            console.log(error);
            setIsLoading(false);
            if (errorHandlers.userNotFound(error)) {
              setSharedValue({ userData });
              navigateFlow({
                flow: SIGN_UP,
                method: GOOGLE,
                step: 1,
              });
            } else if (errorHandlers.deactivated(error)) {
              navigateFlow({
                flow: REACTIVATE,
                step: 0,
              });
            } else {
              setLoginStarted(false);
              if (
                error.response?.status === 500 ||
                error.response?.status === 502
              ) {
                setError(LoginKeys.Error.network);
              } else if (error.key === 'EMAIL_ALREADY_EXISTS') {
                setError(
                  LoginKeys.Error.already_in_use_or_connect_another_method
                );
              } else {
                setError(error?.message || error?.response?.data?.message);
              }
            }
          },
        }
      );
    } catch (error) {
      throw error;
    }
  }

  return (
    <>
      <GButtonWrapper>
        <GoogleLogin onSuccess={handleGoogleSuccess} />
      </GButtonWrapper>

      {/* <FacebookLoginButton
        style={{ marginTop: '-16.5px' }}
        responseCallback={handleFacebookSuccess}
        onClick={handleFacebookSuccess}
      /> */}
      {/* <SocialSignInButton
        type="wallet"
        onClick={() =>
          navigateFlow({
            flow: SIGN_IN,
            method: METAMASK,
            step: 1,
          })
        }
      /> */}
    </>
  );
};

export default SocialLoginSection;

const GButtonWrapper = styled.div`
  height: 40px;
`;
